import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import clsx from 'clsx';

import { useState } from 'react';
import { ChevronRightIcon, ChevronDownIcon, ChevronUpIcon } from '../icons';
import Skeleton from '../Skeleton';
import { convertToTime } from '../../utils/date';

function getStatus(isActive) {
  if (isActive) {
    return 'Abierto';
  }

  return 'Cerrado';
}

function StoreItem(props) {
  const [showSchedules, setShowSchedules] = useState(false);
  const {
    imageUrl,
    name,
    isActive,
    isAvailablePickup,
    isOpen,
    isLoading,
    address,
    scheduled,
    currentSchedule,
    storeId,
    onClick,
    isCurrent,
    isAvailable,
  } = props;

  const isStoreActive = isActive && isOpen && isAvailable && isAvailablePickup;
  const contentWrapper = clsx(
    'flex flex-col w-[calc(100%-127px)] h-full px-3 py-3 rounded-l-lg grow',
    '[@media(max-width:350px)]:rounded-r-lg',
    {
      'bg-baco-primary text-baco-secondary': isStoreActive && !isLoading,
      'bg-gray-200 text-gray-400': !isStoreActive,
      'bg-gray-100 text-gray-300': isLoading,
    },
  );

  const divider = clsx(
    'w-full h-[1px] border-none my-3',
    {
      'bg-baco-secondary': isStoreActive && !isLoading,
      'bg-gray-400': !isStoreActive || isLoading,
    },
  );

  const handleShowMore = (event) => {
    event.stopPropagation();
    setShowSchedules((prev) => !prev);
  };
  const handleStore = () => onClick(storeId);

  if (isLoading) {
    return (
      <div className="flex w-full items-stretch">
        <div className={contentWrapper}>
          <div className="flex justify-between items-start mb-1">
            <Skeleton className="h-5 w-5/12" />
            <Skeleton className="h-4 w-2/12" />
          </div>
          <Skeleton className="h-4 w-8/12 mb-6" />
          <div className="flex justify-between w-full">
            <div className="flex flex-col w-full">
              <Skeleton className="h-3 w-8/12 mb-1" />
              <Skeleton className="h-3 w-4/12" />
            </div>
            <div className="flex flex-col w-full">
              <Skeleton className="h-3 w-8/12 mb-1" />
              <Skeleton className="h-3 w-4/12" />
            </div>
          </div>
          <hr className={divider} />
          <Skeleton className="h-4 w-2/12" />
        </div>
        <div
          className="min-w-[127px] bg-cover bg-center rounded-r-lg bg-gray-400 grow [@media(max-width:350px)]:hidden"
        >
          <Skeleton type="rect" className="h-full w-full rounded-l-none" />
        </div>
      </div>
    );
  }

  return (
    <button
      onClick={isStoreActive ? handleStore : null}
      type="button"
      disabled={!isStoreActive}
      className="flex w-full items-stretch hover:shadow-lg text-left"
    >
      <div className={contentWrapper}>
        <div className="flex justify-between items-start mb-1">
          <h3 className="text-lg leading-5 truncate m-0">{name}</h3>
        </div>
        <p className="text-base leading-4 h-4 truncate mb-6">
          {address}
        </p>
        <div className="relative w-full">
          <div className="flex flex-col w-full">
            <h4 className="text-xs font-bold leading-5 m-0">
              {getStatus(isStoreActive)}
            </h4>
            <div className="flex justify-between items-center">
              <p className="text-[8px] font-bold truncate leading-3 m-0">
                {convertToTime(currentSchedule?.openHour, currentSchedule?.openMinute)}
                &nbsp;-&nbsp;
                {convertToTime(currentSchedule?.closeHour, currentSchedule?.closeMinute)}
              </p>
              <button
                type="button"
                onClick={handleShowMore}
                className="flex gap-x-2 text-[8px] items-center"
              >
                Más horarios
                {showSchedules ? <ChevronUpIcon className="h-3 w-3" /> : <ChevronDownIcon className="h-3 w-3" />}
              </button>
            </div>
          </div>
          <motion.div
            className="flex justify-between w-full overflow-hidden"
            animate={{ height: showSchedules ? 24 : 0 }}
          >
            {scheduled.map(({ days, hours }) => (
              <div className="flex flex-col w-full" key={days}>
                <p className="text-[8px] truncate leading-3 m-0 font-medium">{days}</p>
                <p className="text-[8px] truncate leading-3 m-0">{hours}</p>
              </div>
            ))}
          </motion.div>
        </div>
        <hr className={divider} />
        {isStoreActive ? (
          <p
            className="flex gap-x-3 text-sm leading-3 m-0 font-bold items-center"
          >
            <span>{isCurrent ? 'Estás ordenando aquí' : 'Ordena Aquí'}</span>
            {!isCurrent && (
              <ChevronRightIcon
                className="h-5 w-5 fill-white"
              />
            )}
          </p>
        ) : (
          <p className="text-xs leading-3 m-0">No disponible ahora</p>
        )}
      </div>
      <div
        className="min-w-[127px] bg-gray-400 bg-cover bg-center rounded-r-lg grow [@media(max-width:350px)]:hidden"
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
    </button>
  );
}

StoreItem.propTypes = {
  isActive: PropTypes.bool,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  address: PropTypes.string,
  scheduled: PropTypes.arrayOf(
    PropTypes.shape({
      days: PropTypes.string,
      hours: PropTypes.string,
    }),
  ),
  currentSchedule: PropTypes.shape({
    openHour: PropTypes.number,
    openMinute: PropTypes.number,
    closeHour: PropTypes.number,
    closeMinute: PropTypes.number,
    open: PropTypes.bool,
  }),
  imageUrl: PropTypes.string,
  storeId: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number,
  ]),
  onClick: PropTypes.func,
  isCurrent: PropTypes.bool,
  isAvailable: PropTypes.bool,
  isAvailablePickup: PropTypes.bool,
};
StoreItem.defaultProps = {
  isActive: true,
  isOpen: true,
  isLoading: false,
  imageUrl: null,
  name: '',
  address: '',
  scheduled: [],
  currentSchedule: {},
  storeId: 0,
  onClick: () => null,
  isCurrent: false,
  isAvailable: true,
  isAvailablePickup: true,
};

export default StoreItem;
