import PropTypes from 'prop-types';
// import clsx from 'clsx';

import clsx from 'clsx';
import Skeleton from '../Skeleton';
import { formatCurrency } from '../../utils/currency';

function ProductItem(props) {
  const {
    isLoading,
    imageUrl,
    id,
    name,
    description,
    price,
    discountedPrice,
    onCart,
    tags,
    isAvailable,
    onClick,
  } = props;

  const handleClick = () => onClick(id);

  if (isLoading) {
    return (
      <div
        className="appearance-none flex w-full items-stretch"
      >
        <div
          className="min-w-[127px] bg-gray-400 bg-cover bg-center rounded-l-lg grow"
        >
          <Skeleton type="rect" className="h-full w-full rounded-r-none" />
        </div>
        <div className="flex flex-col w-full px-4 py-4 bg-white rounded-r-lg grow">
          <Skeleton className="h-4 w-8/12 mb-2" />
          <Skeleton className="h-3 w-full mb-1" />
          <Skeleton className="h-3 w-full mb-3" />
          <Skeleton className="h-4 w-4/12" />
        </div>
      </div>
    );
  }

  return (
    <button
      className={
        clsx(
          'appearance-none flex w-full items-stretch transition-shadow hover:shadow-lg cursor-pointer',
          { 'hover:!shadow-none !cursor-not-allowed': !isAvailable },
        )
      }
      onClick={handleClick}
      disabled={!isAvailable}
      type="button"
    >
      <div
        className="relative min-w-[127px] bg-baco-background bg-cover bg-center rounded-l-lg grow overflow-hidden"
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        {!isAvailable && (
          <div
            className={
              clsx(
                'flex items-center absolute top-0 left-0 w-full h-full',
                'text-gray-100 text-center bg-gray-600 bg-opacity-80 p-4',
              )
            }
          >
            Producto No Disponible
          </div>
        )}
      </div>
      <div className="flex flex-col w-full px-4 py-4 bg-white rounded-r-lg grow">
        <h3
          className={
            clsx(
              'text-base text-left font-bold',
              { '!text-gray-400': !isAvailable },
            )
          }
        >
          {(onCart > 0 && isAvailable) && (
            <span
              className={
                clsx(
                  'inline-block h-5 w-5 text-center leading-5 mr-3 rounded-full',
                  'font-bold bg-baco-secondary text-baco-primary text-[10px] m-0',
                )
              }
            >
              {onCart}
            </span>
          )}
          {name}
          {tags.length > 0 && (
            <div className="inline-block ml-3 space-x-1">
              {tags.map((tag) => (
                <span
                  className="px-2 py-1 rounded-md text-xs"
                  style={
                    isAvailable
                      ? { backgroundColor: tag.bgColor, color: tag.fontColor }
                      : { backgroundColor: '#c3c3c3', color: '#fff' }
                  }
                  key={tag.label}
                >
                  {tag.label}
                </span>
              ))}
            </div>
          )}
        </h3>
        <p
          className={
            clsx(
              'block text-xs text-left text-gray-700 mb-3 h-8 overflow-hidden',
              { '!text-gray-400': !isAvailable },
            )
          }
        >
          {
            description.length >= 50
              ? description.substring(0, 50).concat('...')
              : description
          }
        </p>
        <div
          className={
            clsx(
              'flex justify-start items-center space-x-2',
              { '!text-gray-400': !isAvailable },
            )
          }
        >
          {discountedPrice && discountedPrice < price ? (
            <h5
              className={
                clsx(
                  'text-xs font-bold text-gray-500 line-through',
                  { '!text-gray-300': !isAvailable },
                )
              }
            >
              {formatCurrency(price)}
            </h5>
          ) : null}
          <h4
            className="text-sm font-bold"
          >
            {formatCurrency(discountedPrice && discountedPrice < price ? discountedPrice : price)}
          </h4>
        </div>
      </div>
    </button>
  );
}

ProductItem.propTypes = {
  isLoading: PropTypes.bool,
  imageUrl: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  discountedPrice: PropTypes.number,
  isAvailable: PropTypes.bool,
  onCart: PropTypes.number,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      bgColor: PropTypes.string,
      fontColor: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func,
};
ProductItem.defaultProps = {
  isLoading: false,
  imageUrl: null,
  name: '',
  description: '',
  discountedPrice: null,
  isAvailable: false,
  onCart: 0,
  price: null,
  tags: [],
  onClick: () => null,
};

export default ProductItem;
